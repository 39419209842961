import React, { useEffect, useRef, useState } from "react";
import Cardimg from "../assets/images/cart-img.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useGlobalContext } from "../contexts/GlobalContext";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { InfoValidation } from "./FormValidation";
import { Button, Modal } from "react-bootstrap";
import { ReCAPTCHA } from "react-google-recaptcha";
import TermsConditions from "./TermsConditions";
import logo from "../assets/images/main-logo.svg";
import imgPreview from '../assets/images/no-preview-available.png';

const Cart = () => {
    const {
        api_url,
        setIsLoading,
        userInformation,
        getUserInformation,
        cartLength,
        removeSetFromCart,
        setCartLength,
        img_url,
        setCartItems,
        getAllShippingAddress,
        saveForLater,
        isLoading,
        getCartItems,
        cartItems,
        sessionType,
        userId,
        districts,
        stateData,
        handleAddToWishlist,
        handleStateChange,
        setAllShippingAddress,
        allShippingAddress,
        wishlistData,
        getSchoolSet,
        schoolSetData,
    } = useGlobalContext();
    const [infoErrors, setInfoErrors] = useState({});
    const [openForm, setOpenForm] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);

    const [shippingAddressId, setShippingAddressId] = useState(null);
    const [showAddresses, setShowAddresses] = useState(false);
    const [homeAddressCount, setHomeAddressCount] = useState();
    const [showAddressModal, setShowAddressModal] = useState(false);
    const [fillBillingAddress, setFillBillingAddress] = useState(false);
    const [showshippingAddress, setShowShippingAddress] = useState(false);
    const [chooseAddressModal, setChooseAddressModal] = useState(false);
    const [defaultShippingAddress, setDefaultShippingAddress] = useState({});
    const [valueFromRadio, setValueFromRadio] = useState(0);
    const [PaymentMode, setPaymentMode] = useState(1);
    const [schoolAddress, setSchoolAddress] = useState({});
    const [showAllAddress, setShowAllAddress] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const recaptcha = useRef();

    var totalMrp = 0,
        totalDiscountedPrice = 0,
        totalCartQty = 0,
        totalDeliveryCharges = 0,
        totalDiscount = 0;
    const [checkoutRegData, setCheckoutRegData] = useState({
        user_id: userId,
        first_name: "",
        last_name: "",
        email: "",
        phone_no: "",
        state: "",
        district: "",
        post_office: "",
        address: "",
        village: "",
        city: "",
        pincode: "",
        password: "",
    });
    const [billingAddressFilled, setBillingAddressFilled] = useState(false);
    const getHomeAddressCount = () => {
        const data = {
            user_id: userId,
        };
        axios
            .post(`${api_url}/getHomeAddressCount`, data)
            .then((res) => {
                if (res.data.success === 1) {
                    setHomeAddressCount(res.data.data);
                } else {
                    toast.error(res.data.message, {
                        autoClose: 1500,
                        theme: "colored",
                    });
                }
            })
            .catch((error) => {
                toast.error("Axios Error (home Address Count)", {
                    autoClose: 1500,
                    theme: "colored",
                });
            });
    };
    const hasRunRef = useRef(false);
    useEffect(() => {
        const defaultAddress = allShippingAddress.find(
            (address) => address.default_address === 1 && address.address_type === 1
        );
        if (defaultAddress) {
            setValueFromRadio(defaultAddress.id);
        }
    }, [allShippingAddress, setValueFromRadio]);

    useEffect(() => {
        // getCartItems();
        if (!hasRunRef.current) {
            hasRunRef.current = true;
            getUserInformation();
            getAllShippingAddress();
            getHomeAddressCount();
            getSchoolSet();
            getCartItems()
            allShippingAddress.forEach((element) => {
                if (element.address_type === 2) {
                    setSchoolAddress(element);
                }
            });
        }
    }, []);

    let billingAddress;
    if (userInformation) {
        billingAddress = {
            school_cod_status: userInformation?.cod_status,
            user_cod_status: userInformation?.user_cod_status,
            name: userInformation.name,
            phone_no: userInformation.phone_no,
            state: userInformation.state,
            district: userInformation.district,
            post_office: userInformation.post_office,
            village: userInformation.village,
            city: userInformation.city,
            pincode: userInformation.pincode,
            address: userInformation.address,
            school_code: userInformation.school_code,
        };
    }

    const handleChange = (e) => {
        setCheckoutRegData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleState = (e) => {
        setCheckoutRegData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
        handleStateChange(e);
    };

    // Reomove cart item
    const removeCartItem = async (e, product_id) => {
        e.preventDefault();
        const data = {
            user_id: userId,
            product_id: product_id,
            item_type: 0,
        };

        setIsLoading(true);
        try {
            const res = await axios.post(`${api_url}/removeItemFromCart`, data);
            if (res.data.success === 1) {
                toast.warning(res.data.message, {
                    autoClose: 1500,
                    theme: "colored",
                    style: { color: "#000000" },
                });
                setCartItems((cartItems) =>
                    cartItems.filter((item) => product_id !== item.id)
                );
                setCartLength((prev) => prev - 1);
            } else {
                toast.error(res.data.message, {
                    autoClose: 1500,
                    theme: "colored",
                });
            }
        } catch (error) {
            toast.error("Axios Error(removeCartItem)", {
                autoClose: 1500,
            });
        } finally {
            setIsLoading(false);
        }
    };

    // Reomove Set item
    const removeSetItem = async (e, product_id, set_id) => {
        e.preventDefault();
        const data = {
            user_id: userId,
            product_id: product_id,
            item_type: 1,
            set_id: set_id,
        };

        setIsLoading(true);
        try {
            const res = await axios.post(`${api_url}/removeItemFromCart`, data);
            if (res.data.success === 1) {
                toast.warning(res.data.message, {
                    autoClose: 1500,
                    theme: "colored",
                    style: { color: "#000000" },
                });
                setCartItems((cartItems) =>
                    cartItems.filter((item) => product_id !== item.id)
                );
                setCartLength((prev) => prev - 1);
            } else {
                toast.error(res.data.message, {
                    autoClose: 1500,
                    theme: "colored",
                });
            }
        } catch (error) {
            toast.error("Axios Error(removeCartItem)", {
                autoClose: 1500,
            });
        } finally {
            setIsLoading(false);
        }
    };

    //Checkout Registration
    const checkoutRegistration = async (e) => {
        e.preventDefault();
        // const captchaValue = recaptcha.current.getValue()

        let errors = InfoValidation(checkoutRegData);
        setInfoErrors(errors);
        console.log(errors);

        if (Object.keys(errors).length === 0) {
            // if (!captchaValue) {
            //     alert('Please verify the reCAPTCHA!')
            // }
            // else {
            setIsLoading(true);
            try {
                let res = await axios.post(
                    `${api_url}/checkoutRegistration`,
                    checkoutRegData
                );
                if (res.data.success === 1) {
                    const resData = res.data.data;
                    toast.success(res.data.message, {
                        autoClose: 1500,
                        theme: "colored",
                    });
                    const timer = setTimeout(() => {
                        setOpenForm(false);
                        navigate("/otpverification", {
                            state: {
                                user_id: res.data.data.user_id,
                                otp: res.data.data.otp,
                                prevPath: location.pathname,
                            },
                        });
                        // window.location.reload(false);
                    }, 2000);
                    return () => clearTimeout(timer);
                } else {
                    setIsLoading(false);
                    toast.error(res.data.message, {
                        autoClose: 1500,
                        theme: "colored",
                    });
                }
            } catch (error) {
                setIsLoading(false);
                console.log(error);
            }
            // }
        }
    };

    //update Cart quanity
    const updateCartQuantity = async (product_id, scope) => {
        try {
            const data = {
                user_id: userId,
                product_id: product_id,
                scope: scope,
            };
            let res = await axios.put(`${api_url}/updateCartQuantity`, data);
            if (res.data.success === 1) {
                console.log("success");
            } else {
                console.log("server error");
            }
        } catch (error) {
            console.log(error);
        }
    };

    //handle Decrement
    const handleDecrement = (product_id) => {
        setCartItems((cartItems) =>
            cartItems.map((item) =>
                product_id === item.id
                    ? { ...item, qty: Number(item.qty) - (Number(item.qty) > 1 ? 1 : 0) }
                    : item
            )
        );
        updateCartQuantity(product_id, "dec");
    };

    // handle Increment
    const handleIncrement = (pid) => {
        setCartItems((cartItems) =>
            cartItems.map((item) =>
                pid === item.id
                    ? { ...item, qty: Number(item.qty) + (Number(item.qty) < 10 ? 1 : 0) }
                    : item
            )
        );
        updateCartQuantity(pid, "inc");
    };

    //Select Shipping Address
    const selectShippingAddress = async (address_id) => {
        const data = {
            user_id: userId,
            address_id: address_id,
        };
        try {
            let res = await axios.post(`${api_url}/defaultAddress`, data);
            if (res.data.success === 1) {
                console.log("success");
                allShippingAddress.forEach((address) => {
                    if (address.id === address_id) {
                        setDefaultShippingAddress(address);
                    }
                });
            } else {
                toast.error(res.data.success, {
                    autoClose: 1500,
                    theme: "colored",
                });
            }
        } catch (error) {
            toast.success("Axios Error", {
                autoClose: 1500,
                theme: "colored",
            });
        }
    };

    //Address Selection
    const handleAddressSelection = (address_id) => {
        setAllShippingAddress((AllShippingAddress) =>
            AllShippingAddress.map((address) =>
                address.id === address_id
                    ? { ...address, default_address: 1 }
                    : { ...address, default_address: 0 }
            )
        );
        setShippingAddressId(address_id);
        selectShippingAddress(address_id);
    };

    //Continue Checkout
    const ContinueCheckout = () => {
        if (sessionType === "guest") {
            setOpenForm(true);
        } else {
            allShippingAddress.forEach((address) => {
                if (address.default_address === 1) {
                    setShippingAddressId(address.id);
                }
            });
            setShowAddresses(true);
        }
    };

    //handleRadioChange
    const handleRadioChange = (e) => {
        setValueFromRadio(e.target.value);
    };

    //proceedToCheckout
    const proceedToCheckout = () => {
        window.scrollTo({ top: 0, behavior: "instant" });
        const data = {
            user_id: userId,
            shipping_address_id: valueFromRadio,
            mode_of_payment: PaymentMode,
        };
        setIsLoading(true);
        axios
            .post(`${api_url}/proceedToCheckout`, data)
            .then((res) => {
                let orderRes = res.data.data;
                if (res.data.success === 1) {
                    toast.success("Preview Your Order", {
                        autoClose: 1500,
                        theme: "colored",
                    });
                    navigate(`/placeorder/${orderRes.invoice_number}`, {
                        state: {
                            invoice_number: orderRes.invoice_number,
                            order_time: orderRes.order_time,
                        },
                    });
                } else {
                    toast.error("Something Went Wrong", {
                        autoClose: 1500,
                        theme: "colored",
                    });
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.log("data sent", data);
                toast.error("Axios Error", {
                    autoClose: 1500,
                    theme: "colored",
                });
                setIsLoading(false);
            });
    };

    //handleProceedToCheckout
    const handleProceedToCheckout = () => {
        if (valueFromRadio === 0 && homeAddressCount === 0) {
            if (
                billingAddress["name"] === null ||
                billingAddress["phone_no"] === null ||
                billingAddress["state"] === null ||
                billingAddress["district"] === null ||
                billingAddress["post_office"] === null ||
                billingAddress["village"] === null ||
                billingAddress["city"] === null ||
                billingAddress["pincode"] === null
            ) {
                setFillBillingAddress(true);
            } else if (userInformation.userfrom === 1) {
                setShowShippingAddress(true);
            }
            else {
                proceedToCheckout();
            }
        } else if (valueFromRadio === 0 && homeAddressCount !== 0) {
            setChooseAddressModal(true);
        } else if (userInformation.userfrom === 1) {
            setShowShippingAddress(true);
        } else {
            proceedToCheckout();
        }
    };
    //handleOnlineMode
    const handleOnlineMode = (e) => {
        setPaymentMode(1);
        if (!(showAllAddress === true && valueFromRadio !== 0)) {
            setValueFromRadio(0);
        }

        setShowAllAddress(true);
    };

    //handleSchoolCOD
    const handleSchoolCOD = (e) => {
        setPaymentMode(2);
        setValueFromRadio(schoolAddress?.id);
        setShowAllAddress(false);
    };

    var cart_HTML = "";
    var totaltable_HTML = "";
    let isFirstItem = 0;
    let previousSetId = null;
    if (cartItems.length > 0) {
        cart_HTML = (
            <>
                <div className="float-md-start list-area text-sm-start text-center order-1">
                    {cartItems.map((item, i, Items) => {
                        const {
                            id,
                            product_name,
                            item_weight,
                            qty,
                            mrp,
                            set_id,
                            vendor_id,
                            item_type,
                            discounted_price,
                            shipping_charges,
                            product_color,
                            product_size,
                            class_title,
                            description,
                            itemExistInWishlist,
                            itemExistInCart,
                            image,
                            folder,

                            alt,
                        } = item;
                        let product_quantity = Number(qty);
                        let product_id = id;
                        totalMrp += item.mrp * product_quantity;
                        totalDiscountedPrice += Math.round(
                            item.discounted_price * product_quantity
                        );
                        // totalDiscountedPrice +=  item.discounted_price * product_quantity;
                        totalDiscount = Math.round(totalMrp - totalDiscountedPrice);
                        totalCartQty += product_quantity;
                        totalDeliveryCharges += Number(shipping_charges) * product_quantity;

                        // let next_current_set_id = 0;
                        if (i + 1 < Items.length) {
                            var next_current_set_id = Items[i + 1]?.set_id;
                        }

                        let current_set_id = item.set_id;

                        if (item_type === 1) {
                            isFirstItem = isFirstItem + 1;
                            let current_set_id = item.set_id;
                            let displayPreviousSetId = previousSetId;
                            previousSetId = current_set_id;
                            return (
                                <div key={id}>
                                    {isFirstItem === 1 ||
                                        previousSetId !== displayPreviousSetId ? (
                                        <div className="border book-set-class border-danger bg-danger text-white rounded-3 mb-3 py-sm-3 py-1 ps-4 align-items-center d-flex order-md-1 order-2">
                                            <h4 className="mb-0">

                                                {class_title !== null && <>{class_title}</>} Class Set
                                            </h4>
                                            {!showAddresses && (
                                                <div className="ms-auto pe-4 mb-0">
                                                    <button
                                                        onClick={(e) => removeSetFromCart(e, set_id)}
                                                        className="btn rounded-0 text-white fw-bold "
                                                    >
                                                        Remove Set
                                                    </button>
                                                    <br />
                                                </div>
                                            )}

                                            {/* <h6 className="ms-auto pe-4 mb-0">Remove Set</h6> */}
                                        </div>
                                    ) : (
                                        ""
                                    )}

                                    <div className="card border-0 shadow rounded-4 px-2 mb-3">
                                        <div className="card-body d-flex align-items-center justify-content-start flex-wrap gap-2">
                                            {image ? <img
                                                className="rounded-4 mx-sm-0 ms-0 object-fit-contain"
                                                src={`${img_url}/${folder}/${image}`}
                                                width={70}
                                                height={100}
                                                alt="cart-img"
                                            /> : <img
                                                className="rounded-4 mx-sm-0 ms-0 object-fit-contain"
                                                src={imgPreview}
                                                width={70}
                                                height={100}
                                                alt="cart-img"
                                            />}

                                            <div className="mx-2 product-text-section">
                                                <Link
                                                    data-bs-toggle="modal"
                                                    data-bs-target={`#schoolsetmodal${set_id}`}
                                                    className="text-decoration-none text-dark "
                                                >
                                                    <h6 className="text-start d-sm-inline d-none">
                                                        {product_name.length > 20 ? (
                                                            <> {product_name?.slice(0, 20)}...</>
                                                        ) : (
                                                            product_name
                                                        )}
                                                    </h6>
                                                    <h6 className="text-start d-sm-none d-inline">
                                                        {product_name.length > 15 ? (
                                                            <> {product_name?.slice(0, 15)}...</>
                                                        ) : (
                                                            product_name
                                                        )}
                                                    </h6>
                                                </Link>
                                                <p className="mb-0 fw-light text-start">
                                                    {description?.length > 40 ? (
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: description.slice(0, 40) + "...",
                                                            }}
                                                        />
                                                    ) : (
                                                        <span
                                                            dangerouslySetInnerHTML={{ __html: description }}
                                                        />
                                                    )}
                                                </p>
                                                <p className="mb-0 fw-bold text-start">
                                                    {product_size} Class {class_title}
                                                </p>
                                            </div>
                                            <div className=" rounded-3 border border-2 border-dark px-4 py-2 order-btn">
                                                {product_quantity}
                                            </div>
                                            {!showAddresses && (
                                                <button
                                                    onClick={(e) => removeSetItem(e, id, set_id)}
                                                    className="btn btn-outline-dark border-2 text-danger"
                                                >
                                                    <i className="fa-solid fa-trash-can" />
                                                </button>
                                            )}
                                            {/* <button onClick={() => handleAddToWishlist(id)} className="btn btn-dark text-light"><i className="fa-regular fa-heart" /></button> */}
                                            <div className="price ms-auto fs-3 pe-2">
                                                ₹ {discounted_price}
                                            </div>
                                        </div>
                                    </div>
                                    {schoolSetData.map((set, i) => {
                                        const {
                                            id,
                                            set_id,
                                            vendor_id,
                                            vendor,
                                            set_qty,
                                            setclass,
                                            cat_title,
                                            type_title,
                                            board,
                                            org_title,
                                            grade,
                                            set_price,
                                            total_discount,
                                            discounted_price,
                                            set_items,
                                            stock_status,
                                        } = set;
                                        const itemsPerPage = 6;

                                        const totalPages = Math.ceil(
                                            set_items.length / itemsPerPage
                                        );

                                        const startIndex = currentPage * itemsPerPage;
                                        const currentItems = set_items.slice(
                                            startIndex,
                                            startIndex + itemsPerPage
                                        );

                                        return (
                                            <>
                                                {/* {JSON.stringify( schoolSetData)} */}
                                                <div
                                                    key={i}
                                                    className="modal fade py-5"
                                                    id={`schoolsetmodal${set_id}`}
                                                >
                                                    <div className="modal-dialog  modal-xl">
                                                        <div className="modal-content">
                                                            <div className="modal-header bg-danger text-white">
                                                                <h4 className="modal-title ">Set Item</h4>
                                                                <button
                                                                    type="button"
                                                                    className="btn-close"
                                                                    data-bs-dismiss="modal"
                                                                />
                                                            </div>
                                                            <div className="modal-body">
                                                                {/* {JSON.stringify(set)} */}
                                                                <div className="table-responsive p-3">
                                                                    <table className="table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col">S.No</th>
                                                                                <th scope="col">Image</th>
                                                                                <th scope="col">Name</th>
                                                                                <th scope="col">Class</th>
                                                                                <th scope="col">Pricing</th>
                                                                                <th scope="col">{cat_title === 'Text Books' ? 'Publisher' : 'Brand'} </th>
                                                                                <th scope="col">Qty</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {currentItems.map((item, i) => (
                                                                                <tr key={startIndex + i}>
                                                                                    <th scope="row">
                                                                                        {startIndex + i + 1}
                                                                                    </th>
                                                                                    {item.img !== 'inventory_set/' ? <td><img src={`${img_url}/${item.img}`} alt="book1" width={60} /></td> : <td><img src={imgPreview} alt="book1" width={60} /></td>}
                                                                                    {/* <td>
                                                                                        <img
                                                                                            src={`${img_url}/${item.img}`}
                                                                                            alt="book1"
                                                                                            width={60}
                                                                                        />
                                                                                    </td> */}
                                                                                    <td>{item.itemname}</td>
                                                                                    <td>{item.classno}</td>
                                                                                    <td>
                                                                                        {parseFloat(item.price) ===
                                                                                            parseFloat(item?.new_price) ? (
                                                                                            ""
                                                                                        ) : (
                                                                                            <del>₹ {item?.price}</del>
                                                                                        )}
                                                                                        &nbsp;&nbsp;₹{item?.new_price}
                                                                                    </td>
                                                                                    <td>{item.company_name}</td>
                                                                                    <td>{item.qty}</td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div className="row p-3">
                                                                    <div className="text-md-start col-md-6">
                                                                        <h6>
                                                                            Total Price :{" "}
                                                                            <span className="text-danger">
                                                                                ₹{set_price}
                                                                            </span>
                                                                        </h6>
                                                                        <h6>
                                                                            Discount Price :{" "}
                                                                            <span className="text-danger">
                                                                                ₹{total_discount}
                                                                            </span>
                                                                        </h6>
                                                                        <h6>
                                                                            Final Price :{" "}
                                                                            <span className="text-danger">
                                                                                ₹{discounted_price}
                                                                            </span>
                                                                        </h6>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="justify-content-md-end justify-content-center d-flex">
                                                                            <h5>
                                                                                {" "}
                                                                                <button
                                                                                    className="p-0 border-0 bg-transparent"
                                                                                    onClick={() =>
                                                                                        setCurrentPage(currentPage - 1)
                                                                                    }
                                                                                    disabled={currentPage <= 0}
                                                                                >
                                                                                    <i
                                                                                        className={`ti ti-circle-arrow-left-filled ${currentPage <= 0
                                                                                            ? "text-secondary"
                                                                                            : "text-dark"
                                                                                            }`}
                                                                                    />
                                                                                </button>
                                                                                <span className="mx-1">
                                                                                    {currentPage + 1}
                                                                                </span>
                                                                                <button
                                                                                    className="p-0 border-0 bg-transparent"
                                                                                    onClick={() =>
                                                                                        setCurrentPage(currentPage + 1)
                                                                                    }
                                                                                    disabled={
                                                                                        currentPage >= totalPages - 1
                                                                                    }
                                                                                >
                                                                                    <i
                                                                                        className={`ti ti-circle-arrow-right-filled ${currentPage >= totalPages - 1
                                                                                            ? "text-secondary"
                                                                                            : "text-dark"
                                                                                            }`}
                                                                                    />
                                                                                </button>
                                                                            </h5>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    })}
                                </div>
                            );
                        } else {
                            let prevactive = previousSetId;
                            previousSetId = null;
                            //    let currentactive=prevactive;

                            return (
                                <div id="cart_item" key={i}>
                                    {/* {JSON.stringify(item)}
                                        {JSON.stringify(previousSetId)} */}
                                    {prevactive === null ? (
                                        ""
                                    ) : (
                                        <div className="border border-danger book-set-class bg-dark text-white rounded-3 mb-3 py-sm-3 py-1 ps-4 align-items-center d-flex ">
                                            <h4 className="mb-0"> Other Books</h4>
                                        </div>
                                    )}
                                    <div className="card border-0 shadow rounded-4 px-2 mb-3">
                                        <div className="card-body d-flex align-items-center justify-content-start flex-wrap gap-2">
                                            <img
                                                className="rounded-4 mx-sm-0 ms-0 object-fit-contain"
                                                src={`${img_url}/${folder}/${image}`}
                                                width={70}
                                                height={100}
                                                alt="cart-img"
                                            />
                                            <div className="mx-2 product-text-section">
                                                <Link
                                                    to={`/product/${product_id}`}
                                                    className="text-decoration-none text-dark "
                                                >
                                                    <h6 className="text-start d-sm-block d-none">
                                                        {product_name.length > 20 ? (
                                                            <> {product_name?.slice(0, 20)}...</>
                                                        ) : (
                                                            product_name
                                                        )}
                                                    </h6>
                                                    <h6 className="text-start d-sm-none d-block">
                                                        {product_name.length > 15 ? (
                                                            <> {product_name?.slice(0, 15)}...</>
                                                        ) : (
                                                            product_name
                                                        )}
                                                    </h6>
                                                </Link>
                                                <p className="mb-0 fw-light text-start discriptiontext">
                                                    {" "}
                                                    {description?.length > 30 ? (
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: description.slice(0, 30) + "...",
                                                            }}
                                                        />
                                                    ) : (
                                                        <span
                                                            dangerouslySetInnerHTML={{ __html: description }}
                                                        />
                                                    )}
                                                </p>
                                                <p className="mb-0 fw-bold text-start">
                                                    {product_size} Class {class_title}
                                                </p>
                                            </div>
                                            <div
                                                className={`rounded-3 border border-2 border-dark py-2 order-btn ${showAddresses ? "px-4" : ""
                                                    }`}
                                            >
                                                {!showAddresses && (
                                                    <button
                                                        className="border-0 bg-transparent mx-1 fw-bold"
                                                        onClick={() => handleDecrement(id)}
                                                    >
                                                        -
                                                    </button>
                                                )}
                                                {product_quantity}
                                                {!showAddresses && (
                                                    <button
                                                        className="border-0 bg-transparent mx-1  fw-bold"
                                                        onClick={() => handleIncrement(id)}
                                                    >
                                                        +
                                                    </button>
                                                )}
                                            </div>
                                            {!showAddresses && (
                                                <button
                                                    onClick={(e) => removeCartItem(e, id)}
                                                    className="btn btn-outline-dark border-2 text-danger"
                                                >
                                                    <i className="fa-solid fa-trash-can" />
                                                </button>
                                            )}
                                            {!showAddresses && (
                                                <button
                                                    onClick={() => saveForLater(id)}
                                                    className="btn btn-dark text-light"
                                                >
                                                    <i className={`fa-regular fa-heart ${itemExistInWishlist ? 'wishlist-added' : ''}`} />
                                                </button>
                                            )}

                                            <div className="price ms-auto fs-3 pe-4">
                                                ₹ {product_quantity * discounted_price}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    })}
                </div>
            </>
        );

        totaltable_HTML = (
            <>
                {cartItems.map((item, i) => {
                    const { product_name, qty, mrp, discounted_price } = item;
                    let product_quantity = Number(qty);
                    return (
                        <div key={i}>
                            <div className={`row g-1 bill-drop text-start ${cartItems.length !== (i + 1) ? 'border-0 border-bottom border-1 ' : ''} py-2 `}>
                                <div className="col-7 px-md-3 px-2">
                                    {product_name.length > 25 ? (
                                        <> {product_name?.slice(0, 25)}...</>
                                    ) : (
                                        product_name
                                    )}

                                </div>
                                <div className="col-1 ms-auto">{product_quantity}</div>
                                <div className="col-4 ms-auto text-end">
                                    {parseInt(mrp) === parseInt(discounted_price) ? (
                                        ""
                                    ) : (
                                        <del>₹{mrp}</del>
                                    )}{" "}
                                    ₹{discounted_price}
                                </div>
                                {/* <div className="col-7 px-md-3 px-2 fw-bold">
                                 Total :
                                </div>
                                <div className="col-5 px-md-3  fw-bold text-end">
                                ₹{product_quantity * discounted_price}
                                </div> */}
                            </div>
                        </div>
                    );
                })}
            </>
        );
    } else {
        cart_HTML = (
            <div>
                <h3 className=" mt-3 text-center">Your Cart is Empty</h3>
                <div className="row">
                    <div className="col-12 text-center">
                        <img
                            src={require("../assets/images/empty carrt.png")}
                            className="img-fluid d-block mx-auto"
                            alt=""
                        />
                    </div>
                    <div className="col-12 py-5">
                        <div className="text-center">
                            <Link to={"/marketplace"} onClick={() => window.scrollTo({ top: 0, behavior: 'instant' })} className="btn btn-danger">
                                Add Items to Cart
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <>
            <ToastContainer />

            <main id="cart" className="mb-5 pb-5">
                <div className="eVyapari-content w-100">
                    <div className="text-center  mb-2 d-block d-md-none">
                        <Link to={"/"} className="navbar-brand order-md-1 mx-auto">
                            <img src={logo} alt="e-vyapari-logo" />
                        </Link>
                    </div>
                </div>
                {/* {console.log(cartItems)} */}
                <section id="cart-top" className="py-md-5 py-2">
                    <div className="container">
                        <div className="row g-4 d-flex justify-content-between">
                            <div className="col-md-4">
                                <h5 className="my-cart text-start">
                                    {" "}
                                    <i
                                        className="ti ti-circle-arrow-left cursor-pointer"
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => navigate(-1)}
                                    />{" "}
                                    <span className="d-inline">My Cart</span>
                                </h5>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="cart-item-list">
                    <div className="container">
                        <div className="d-flex flex-column gap-3 d-md-block">
                            <span className={`${showAddresses ? "d-md-block d-none" : "order-md-1 order-1"}`}>

                                {cart_HTML}
                            </span>
                            {cartItems.length !== 0 && (
                                <div className="float-md-end bill-details order-md-2 order-0  mb-3">
                                    {!openForm && !showAddresses && (
                                        <div className="card border-2 border-dark rounded-3 py-3">
                                            <div className="card-body">
                                                <h4 className="text-center checkout-card">
                                                    Total Bill Checkout
                                                </h4>
                                                <div className="border-bottom border-secondary p-2">
                                                    <div className="d-flex fw-bold">
                                                        <button
                                                            className="checkout-card border-0 bg-transparent pb-2 fw-bold p-0"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#books-list"
                                                        >
                                                            Order Review
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="18"
                                                                height="18"
                                                                viewBox="0 0 24 24"
                                                                fill="currentColor"
                                                                className="icon icon-tabler icons-tabler-filled icon-tabler-caret-down"
                                                            >
                                                                <path
                                                                    stroke="none"
                                                                    d="M0 0h24v24H0z"
                                                                    fill="none"
                                                                />
                                                                <path d="M18 9c.852 0 1.297 .986 .783 1.623l-.076 .084l-6 6a1 1 0 0 1 -1.32 .083l-.094 -.083l-6 -6l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057v-.118l.005 -.058l.009 -.06l.01 -.052l.032 -.108l.027 -.067l.07 -.132l.065 -.09l.073 -.081l.094 -.083l.077 -.054l.096 -.054l.036 -.017l.067 -.027l.108 -.032l.053 -.01l.06 -.01l.057 -.004l12.059 -.002z" />
                                                            </svg>
                                                        </button>
                                                        <span className="ms-auto">{cartItems.length}</span>
                                                    </div>
                                                    <div id="books-list" className="collapse">
                                                        {totaltable_HTML}
                                                    </div>
                                                </div>
                                                <div className="d-flex border-bottom border-secondary fw-bold p-2">
                                                    <span className="checkout-card pb-2">Subtotal</span>
                                                    <span className="ms-auto">
                                                        ₹{totalMrp}
                                                    </span>

                                                </div>
                                                <div className="d-flex border-bottom border-secondary fw-bold p-2">
                                                    <span className="checkout-card pb-2">Discount</span>
                                                    <span className="ms-auto">
                                                        - ₹ {totalMrp - totalDiscountedPrice}
                                                    </span>
                                                </div>
                                                <div className="d-flex fw-bold p-2">
                                                    <span className="checkout-card  pb-2">
                                                        Total amount to be Paid
                                                    </span>
                                                    <span className="ms-auto">
                                                        ₹ {totalDiscountedPrice}
                                                    </span>
                                                </div>
                                                {/* {
                                            showAddresses === false &&
                                            <>
                                                <Link to={`/myschool`} className="btn btn-outline-pink w-100 mt-3">Buy More School Sets</Link> */}

                                                <button
                                                    className="checkout-card btn btn-danger w-100 pb-2"
                                                    onClick={() => ContinueCheckout()}
                                                >
                                                    Checkout
                                                </button>
                                            </div>
                                        </div>
                                    )}

                                    {openForm && (
                                        <div className=" bg-white border border-1 p-4 rounded-4 border border-dark shadow-btm mb-3">
                                            <h4 className="fw-bold text-center mb-4">SignUp</h4>
                                            <form className="bg-white">
                                                <div className="row">
                                                    <div className="col-lg-6 text-start mb-3">
                                                        <label
                                                            htmlFor="fname"
                                                            className="form-label text-secondary"
                                                        >
                                                            First Name<span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            name="first_name"
                                                            value={checkoutRegData.first_name}
                                                            id="fname"
                                                            placeholder="First Name"
                                                        />
                                                        <div className="text-danger">
                                                            {infoErrors.first_name}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 text-start mb-3">
                                                        <label
                                                            htmlFor="lname"
                                                            className="form-label text-secondary"
                                                        >
                                                            Last Name<span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="lname"
                                                            onChange={handleChange}
                                                            name="last_name"
                                                            value={checkoutRegData.last_name}
                                                            placeholder="Last Name"
                                                        />
                                                        <div className="text-danger">
                                                            {infoErrors.last_name}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 text-start mb-3">
                                                        <label
                                                            htmlFor="email"
                                                            className="form-label text-secondary"
                                                        >
                                                            Email
                                                        </label>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            name="email"
                                                            value={checkoutRegData.email}
                                                            id="email"
                                                            placeholder="Email"
                                                        />
                                                        <div className="text-danger">
                                                            {infoErrors.email}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 text-start">
                                                        <label
                                                            htmlFor="number"
                                                            className="form-label text-secondary"
                                                        >
                                                            Contact Number<span className="text-danger">*</span>
                                                        </label>
                                                        <div className="input-group">
                                                            <input
                                                                type="tel"
                                                                className="form-control border border-end-0"
                                                                onChange={handleChange}
                                                                maxLength={10}
                                                                name="phone_no"
                                                                value={checkoutRegData.phone_no}
                                                                id="number"
                                                                placeholder="Contact Number"
                                                            />
                                                            <span className="input-group-text bg-white">
                                                                <span className="material-symbols-outlined text-secondary">
                                                                    <i className="ti ti-device-mobile-check" />
                                                                </span>
                                                            </span>
                                                        </div>
                                                        <div className="text-danger">
                                                            {infoErrors.phone_no}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 text-start mb-3 mt-3">
                                                        <label
                                                            htmlFor="password"
                                                            className="form-label text-secondary"
                                                        >
                                                            Create Password<span className="text-danger">*</span>
                                                        </label>
                                                        <div className="input-group">
                                                            <input
                                                                type="password"
                                                                id="password"
                                                                onChange={handleChange}
                                                                name="password"
                                                                value={checkoutRegData.password}
                                                                placeholder="********"
                                                                className="form-control border border-end-0"
                                                            />
                                                            <span className="input-group-text bg-white">
                                                                {/* <span className="material-symbols-outlined text-secondary">visibility</span> */}
                                                            </span>
                                                        </div>
                                                        <div className="text-danger">
                                                            {infoErrors.password}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 text-start mb-3">
                                                        <label htmlFor="sel-ste" className="form-label">
                                                            Select State<span className="text-danger">*</span>
                                                        </label>
                                                        <select
                                                            className="form-select"
                                                            onChange={(e) => handleState(e)}
                                                            name="state"
                                                            value={checkoutRegData.state}
                                                        >
                                                            <option selected>Select State</option>{" "}
                                                            {stateData.map((state, i) => {
                                                                return (
                                                                    <option key={i} value={state.state_name}>
                                                                        {state.state_name}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                        <div className="text-danger mb-3">
                                                            {infoErrors.state}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 text-start mb-3">
                                                        <label htmlFor="sel-dis" className="form-label">
                                                            Select District<span className="text-danger">*</span>
                                                        </label>
                                                        <select
                                                            className="form-select"
                                                            onChange={handleChange}
                                                            name="district"
                                                            value={checkoutRegData.district}
                                                        >
                                                            <option selected>Select District</option>{" "}
                                                            {districts.map((district, i) => {
                                                                return (
                                                                    <option key={i} value={district}>
                                                                        {district}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                        <div className="text-danger mb-3">
                                                            {infoErrors.district}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 text-start mb-3">
                                                        <label
                                                            htmlFor="post-office"
                                                            className="form-label text-secondary"
                                                        >
                                                            Post Office<span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control text-black"
                                                            onChange={handleChange}
                                                            name="post_office"
                                                            value={checkoutRegData.post_office}
                                                            id="post-office"
                                                            placeholder="Post Office"
                                                        />
                                                        <div className="text-danger">
                                                            {infoErrors.post_office}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 text-start mb-3">
                                                        <label
                                                            htmlFor="pin-code"
                                                            className="form-label text-secondary"
                                                        >
                                                            Pin Code<span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control text-black"
                                                            onChange={handleChange}
                                                            name="pincode"
                                                            value={checkoutRegData.pincode}
                                                            id="pincode"
                                                            placeholder='Pin Code'
                                                        />
                                                        <div className="text-danger">
                                                            {infoErrors.pincode}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 text-start mb-3">
                                                        <label
                                                            htmlFor="landmark"
                                                            className="form-label text-secondary"
                                                        >
                                                            Landmark / House number / Building name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            name="address"
                                                            value={checkoutRegData.address}
                                                            id="address"
                                                            placeholder="Landmark"
                                                        />
                                                        {/* <select className="form-select">
                                                        <option selected>Landmark</option>
                                                        <option value={1}>Hospital</option>
                                                    </select> */}
                                                    </div>
                                                    <div className="col-lg-6 text-start mb-3">
                                                        <label
                                                            htmlFor="ward"
                                                            className="form-label text-secondary"
                                                        >
                                                            Village / Ward No. / Sec.<span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            name="village"
                                                            value={checkoutRegData.village}
                                                            id="ward"
                                                            placeholder="Village / Ward No. / Sec."
                                                        />
                                                        <div className="text-danger">
                                                            {infoErrors.village}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 text-start mb-3 pb-3">
                                                        <label
                                                            htmlFor="city"
                                                            className="form-label text-secondary "
                                                        >
                                                            City<span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            name="city"
                                                            value={checkoutRegData.city}
                                                            id="city"
                                                            placeholder="City"
                                                        />
                                                        <div className="text-danger">{infoErrors.city}</div>
                                                    </div>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            checked
                                                            type="checkbox"
                                                            value=""
                                                            id="flexCheckDefault"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            for="flexCheckDefault"
                                                        >
                                                            I agree to accept the terms and conditions
                                                            <span
                                                                className="cursor-pointer text-decoration-none text-danger"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#ReadMoreModal"
                                                            >
                                                                READ.
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <TermsConditions />
                                                </div>
                                                <a
                                                    href="checkout-sign2.html"
                                                    className="btn btn-danger w-100"
                                                    onClick={(e) => checkoutRegistration(e)}
                                                    type="button"
                                                >
                                                    Register
                                                </a>
                                                <p className="pt-4 text-start mb-0">
                                                    Already have account ? &nbsp;
                                                    <Link
                                                        to="/login"
                                                        className="text-decoration- fs-6 text-danger"
                                                    >
                                                        <span className="text-danger">Login</span>
                                                    </Link>{" "}

                                                </p>
                                            </form>
                                        </div>
                                    )}

                                    {showAddresses && (
                                        <>
                                            <div className="row">
                                                <div className="col-12 my-2">
                                                    <div className="col-md-12">
                                                        <div className="text-md-end mb-3">
                                                            <Link to={'/addaddress'} className="btn btn-danger border-dark shadow-btm px-5 py-2 rounded-3 mt-2 btn-font-size mb-2"><i className="ti ti-plus me-3" />Add New Address </Link>
                                                        </div>
                                                    </div>
                                                    <div className="border border-dark rounded-3 shadow-btm p-3 bg-white">
                                                        <h6 className="bg-danger p-2 rounded d-flex text-white justify-content-between align-items-center">
                                                            Billing Address
                                                        </h6>
                                                        <div className="form-check text-start pb-2">
                                                            {homeAddressCount === 0 && (
                                                                <div className="form-check">
                                                                    <input
                                                                        type="radio"
                                                                        className="form-check-input border-danger border-2"
                                                                        name="ship_address"
                                                                        value={0}
                                                                        onChange={(e) => handleRadioChange(e)}
                                                                        title="Choose Shipping Address"
                                                                        id="same_as_bill"
                                                                        checked={
                                                                            parseInt(valueFromRadio) ===
                                                                                parseInt(0)
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        // defaultChecked={userInformation?.userfrom === 0}
                                                                    // defaultChecked
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="flexCheckDefault"
                                                                    >
                                                                        <span className="text-danger r_font2">
                                                                            Shipping Address Same as Billing Address
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="d-flex text-align-center mt-3">
                                                            <i className="ti ti-home-filled fs-1 text-danger me-3 ps-5" />
                                                            <div>
                                                                <h5 className="fw-bold mb-0">Home</h5>

                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <ul className="list-unstyled">
                                                                <li className="fw-bold mt-2">
                                                                    {billingAddress?.name},
                                                                </li>
                                                                <li>
                                                                    {billingAddress.phone_no &&
                                                                        ` ${billingAddress.phone_no}`}
                                                                </li>
                                                                <li>
                                                                    {billingAddress.alternate_phone &&
                                                                        `, ${billingAddress.alternate_phone}`}
                                                                </li>
                                                                <li>
                                                                    {billingAddress.village &&
                                                                        ` ${billingAddress.village} , `}

                                                                    {billingAddress.address &&
                                                                        `${billingAddress.address}`}
                                                                </li>
                                                                {billingAddress.pincode && (
                                                                    <li>
                                                                        <span className=" text-black fw-bold">
                                                                            Pin code:
                                                                        </span>{" "}
                                                                        {billingAddress.pincode &&
                                                                            `${billingAddress.pincode}`}
                                                                    </li>
                                                                )}
                                                                {billingAddress.post_office && (
                                                                    <li>
                                                                        <span className="text-dark fw-bold">
                                                                            Post Office:
                                                                        </span>{" "}
                                                                        {billingAddress.post_office}
                                                                    </li>
                                                                )}
                                                                <li className="text-dark fw-bold">
                                                                    {billingAddress.city &&
                                                                        `${billingAddress.city}`}
                                                                    {billingAddress.district &&
                                                                        `, ${billingAddress.district}`}
                                                                    {billingAddress.state &&
                                                                        `, ${billingAddress.state}`}
                                                                </li>
                                                            </ul>
                                                            <div className="edit">
                                                                <Link
                                                                    to={"/myinformation"}
                                                                    className="btn btn-sm btn-danger border-dark shadow-btm rounded-3 px-3"
                                                                >
                                                                    <i className="ti ti-edit pe-3" />
                                                                    Edit{" "}
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    {/* {JSON.stringify(allShippingAddress)} */}
                                                    {/* {JSON.stringify(valueFromRadio)} */}
                                                    {allShippingAddress.map((address, i) => {
                                                        const {
                                                            id,
                                                            user_id,
                                                            default_address,
                                                            name,
                                                            phone_no,
                                                            alternate_phone,
                                                            village,
                                                            city,
                                                            address_type,
                                                            state,
                                                            district,
                                                            post_office,
                                                            pincode,
                                                        } = address;

                                                        return (
                                                            default_address === 1 &&
                                                            address_type === 1 && (
                                                                <div className="col-12 my-2">
                                                                    <div className="border border-dark rounded-3 shadow-btm p-3 bg-white">
                                                                        <div className="bg-danger p-2 rounded d-flex justify-content-between align-items-center">
                                                                            <h6 className="text-white m-0">
                                                                                Shipping Address
                                                                            </h6>
                                                                            {userInformation?.userfrom === 1 && <div className="text-white fw-bold">Delivery Charges : {totalDeliveryCharges}</div>}

                                                                        </div>
                                                                        {/* <div className="form-check text-start pb-5">
                                                                        <input type="checkbox" value={id} onChange={(e) => handleRadioChange(e)} className="form-check-input border-danger border-2" id="check2" name="condition" title="Choose Shipping Address" defaultValue="something" />
                                                                    </div> */}

                                                                        <div className="d-flex text-align-center mt-3">
                                                                            <i className="ti ti-home-filled fs-1 text-danger me-3 ps-5" />
                                                                            <div>
                                                                                <h5 className="fw-bold mb-0">

                                                                                    {address_type === 1
                                                                                        ? "Home"
                                                                                        : "School"}
                                                                                </h5>
                                                                                {/* <p>{name},</p> */}
                                                                            </div>
                                                                            <div
                                                                                className="ms-3 text-dark d-flex"
                                                                                onChange={(e) => handleRadioChange(e)}
                                                                            >

                                                                                <div className="form-check text-start cursor-pointer">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        value={id}
                                                                                        onChange={(e) =>
                                                                                            handleRadioChange(e)
                                                                                        }
                                                                                        className="form-check-input border-danger fs-6 border-2"
                                                                                        checked={
                                                                                            parseInt(valueFromRadio) ===
                                                                                                parseInt(id)
                                                                                                ? true
                                                                                                : false
                                                                                        }
                                                                                        id="check2"
                                                                                        name="condition"
                                                                                        title="Choose Shipping Address"
                                                                                        defaultValue="something"
                                                                                    />
                                                                                    &nbsp;Choose shipping address
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex justify-content-between">
                                                                            <ul className="list-unstyled">
                                                                                <li className="fw-bold mt-2">
                                                                                    {name},
                                                                                </li>
                                                                                <li>
                                                                                    {phone_no}
                                                                                    {alternate_phone &&
                                                                                        `, ${alternate_phone}`}
                                                                                </li>
                                                                                <li>
                                                                                    {billingAddress.alternate_phone &&
                                                                                        `, ${billingAddress.alternate_phone}`}
                                                                                </li>
                                                                                <li>
                                                                                    {/* {`${village}`} */}
                                                                                    {village &&
                                                                                        `, ${village} , `}
                                                                                    {address.address &&
                                                                                        ` ${address.address}`}
                                                                                </li>
                                                                                {pincode && (
                                                                                    <li>
                                                                                        <span className=" text-black fw-bold">
                                                                                            Pin code:
                                                                                        </span>{" "}
                                                                                        {pincode && `${pincode}`}
                                                                                    </li>
                                                                                )}
                                                                                {post_office && (
                                                                                    <li>
                                                                                        <span className="text-dark fw-bold">
                                                                                            Post Office:
                                                                                        </span>{" "}
                                                                                        {post_office}
                                                                                    </li>
                                                                                )}
                                                                                <li className="text-dark fw-bold">
                                                                                    {city && `${city}`}
                                                                                    {district && `, ${district}`}
                                                                                    {state && `, ${state}`}
                                                                                </li>
                                                                            </ul>
                                                                            <div className="edit">
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={() =>
                                                                                        setShowAddressModal(true)
                                                                                    }
                                                                                    className="btn btn-sm btn-danger border-dark shadow-btm rounded-3 px-3"
                                                                                >
                                                                                    <i className="ti ti-edit pe-3" />
                                                                                    Edit{" "}
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        );
                                                    })}
                                                    {allShippingAddress.map((address, i) => {
                                                        const {
                                                            id,
                                                            user_id,
                                                            default_address,
                                                            name,
                                                            phone_no,
                                                            school_name,
                                                            alternate_phone,
                                                            village,
                                                            city,
                                                            address_type,
                                                            state,
                                                            district,
                                                            post_office,
                                                            pincode,
                                                        } = address;
                                                        return (
                                                            address_type === 2 && (
                                                                <div className="col-12 my-2 mt-3">
                                                                    <div className="border border-dark rounded-3 shadow-btm p-3 bg-white">
                                                                        {/* <div className='bg-danger p-2 rounded'>
                                                                        <h6 className='text-white m-0 d-flex justify-content-between' data-bs-toggle="collapse" data-bs-target="#books-list1">School pickup address<i className="ti ti-chevron-down" />

                                                                        </h6>
                                                                    </div> */}
                                                                        <div className="bg-danger p-2 rounded d-flex justify-content-between align-items-center">
                                                                            <h6
                                                                                className="text-white m-0 d-flex justify-content-between align-items-center"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target="#books-list1"
                                                                            >
                                                                                School pickup address
                                                                                <i className="ti ti-chevron-down" />
                                                                            </h6>
                                                                            {userInformation?.userfrom === 1 && <div className="text-white fw-bold">Free Delivery</div>}

                                                                            {/* <div
                                                                                className=" text-white d-flex"
                                                                                onChange={(e) => handleRadioChange(e)}
                                                                            >
                                                                                Select &nbsp;{" "}
                                                                                <div className="form-check text-start cursor-pointer">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        value={id}
                                                                                        onChange={(e) =>
                                                                                            handleRadioChange(e)
                                                                                        }
                                                                                        className="form-check-input border-white border-2"
                                                                                        checked={
                                                                                            parseInt(valueFromRadio) ===
                                                                                                parseInt(id)
                                                                                                ? true
                                                                                                : false
                                                                                        }
                                                                                        id="check2"
                                                                                        name="condition"
                                                                                        title="Choose Shipping Address"
                                                                                        defaultValue="something"
                                                                                    />
                                                                                </div>{" "}
                                                                            </div> */}
                                                                        </div>
                                                                        <div id="books-list1" className="collapse">
                                                                            {/* <div className="form-check text-start pb-5">
                                                                            <input type="checkbox" name='ship_address' value={id} onChange={(e) => handleRadioChange(e)} required title="Choose Shipping Address" id="school_add" className="form-check-input border-danger border-2" defaultValue="something" />

                                                                        </div> */}

                                                                            <div className="d-flex text-align-center mt-3">
                                                                                <i className="ti ti-school fs-1 text-danger me-3  " />
                                                                                <div>
                                                                                    <h5 className="fw-bold mb-0">

                                                                                        {address_type === 1 ? (
                                                                                            <span>Home</span>
                                                                                        ) : (
                                                                                            <span>School</span>
                                                                                        )}
                                                                                    </h5>

                                                                                </div>
                                                                                <div
                                                                                    className="ms-3 text-dark d-flex"
                                                                                    onChange={(e) => handleRadioChange(e)}
                                                                                >

                                                                                    <div className="form-check text-start cursor-pointer">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            value={id}
                                                                                            onChange={(e) =>
                                                                                                handleRadioChange(e)
                                                                                            }
                                                                                            className="form-check-input border-danger fs-6 border-2"
                                                                                            checked={
                                                                                                parseInt(valueFromRadio) ===
                                                                                                    parseInt(id)
                                                                                                    ? true
                                                                                                    : false
                                                                                            }
                                                                                            id="check2"
                                                                                            name="condition"
                                                                                            title="Choose Shipping Address"
                                                                                            defaultValue="something"
                                                                                        />
                                                                                        &nbsp;Choose shipping address
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex justify-content-between">
                                                                                <ul className="list-unstyled">
                                                                                    <li className="fw-bold mt-2">
                                                                                        {name},
                                                                                    </li>
                                                                                    <li>
                                                                                        {phone_no}
                                                                                        {alternate_phone &&
                                                                                            `, ${alternate_phone}`}
                                                                                    </li>

                                                                                    <li>
                                                                                        {school_name}
                                                                                        {address.address &&
                                                                                            `, ${address.address}`}
                                                                                    </li>
                                                                                    <li>
                                                                                        {`${village}`}
                                                                                        {address.address &&
                                                                                            `, ${address.address}`}
                                                                                    </li>
                                                                                    {pincode && (
                                                                                        <li>
                                                                                            <span className=" text-black fw-bold">
                                                                                                Pin code:
                                                                                            </span>{" "}
                                                                                            {pincode && `${pincode}`}
                                                                                        </li>
                                                                                    )}
                                                                                    {post_office && (
                                                                                        <li>
                                                                                            <span className="text-dark fw-bold">
                                                                                                Post Office:
                                                                                            </span>{" "}
                                                                                            {post_office}
                                                                                        </li>
                                                                                    )}
                                                                                    <li className="text-dark fw-bold">
                                                                                        {city && `${city} , `}
                                                                                        {district && `${district}`}
                                                                                        {state && `, ${state}`}
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        );
                                                    })}
                                                </div>
                                                <div className="col-12 mt-3">
                                                    <div className="border border-dark shadow-btm rounded-3 p-3">
                                                        <h5>Payment Method</h5>
                                                        <div className="d-flex gap-3">
                                                            <div className="form-check">
                                                                <label className="form-check-label" id="online_payment_method">
                                                                    <span className='text-danger'>Online</span>
                                                                </label>
                                                                <input type="radio" className="form-check-input text-danger border-2 border-danger" name='mode_of_payment' value={1} onChange={(e) => handleOnlineMode()} title="Choose Peyment Method" id="online_payment_method" defaultChecked />
                                                            </div>
                                                            {
                                                                (billingAddress?.school_cod_status === 1 || billingAddress?.user_cod_status === 1) &&
                                                                <div className="form-check">
                                                                    <label className="form-check-label" htmlFor="cod_payment_method">
                                                                        <span className='text-danger'>Cash on Delivery</span>
                                                                    </label>

                                                                    {
                                                                        billingAddress?.user_cod_status === 1 ?
                                                                            <input type="radio" className="form-check-input text-danger border-2 border-danger" name='mode_of_payment' value={2} onChange={(e) => setPaymentMode(2)} title="Choose Shipping Address" id="cod_payment_method" />
                                                                            :
                                                                            <input type="radio" className="form-check-input text-danger border-2 border-danger" name='mode_of_payment' value={2} onChange={(e) => handleSchoolCOD(e)} title="Choose Shipping Address" id="cod_payment_method" />
                                                                    }
                                                                </div>
                                                            }
                                                            {console.log(valueFromRadio)}
                                                            {/* <input
                                                                type="radio"
                                                                name="mode_of_payment"
                                                                value={1}
                                                                onChange={(e) => handleOnlineMode()} id="online_payment_method" defaultChecked
                                                                title="Choose Peyment Method"
                                                                checked

                                                                defaultValue="HTML"
                                                                className="text-danger onlinepayment"
                                                            />
                                                            <label
                                                                htmlFor="online_payment_method"
                                                                className="text-danger"
                                                            >
                                                                &nbsp;Online
                                                            </label> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 my-3 proceedcheckoutbtn">
                                                    {showAddresses && (
                                                        <button
                                                            type="button"
                                                            className="btn blink-shadow-button text-white w-100 "
                                                            onClick={() => handleProceedToCheckout()}
                                                        >
                                                            Proceed to checkout
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                            {console.log(valueFromRadio, 'val')
                            }
                        </div>
                    </div>
                </section>
                <Modal
                    size="lg"
                    autoFocus={false}
                    centered
                    show={showAddressModal}
                    onHide={() => setShowAddressModal(false)}
                >
                    <Modal.Header className="bg-pink text-white" closeButton>
                        <Modal.Title>
                            <h5>SHIPPING ADDRESS</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="bg-white">
                            {allShippingAddress.map((address, i) => {
                                const {
                                    id,
                                    user_id,
                                    name,
                                    default_address,
                                    phone_no,
                                    school_name,
                                    alternate_phone,
                                    village,
                                    city,
                                    address_type,
                                    state,
                                    district,
                                    post_office,
                                    pincode,
                                } = address;
                                let address_id = id;
                                return (
                                    address_type === 1 && (
                                        <div key={i} id="address" className="px-4">
                                            <div className="form-check">
                                                <input
                                                    type="radio"
                                                    onClick={() => handleAddressSelection(address_id)}
                                                    checked={default_address === 1 ? true : false}
                                                    className="form-check-input  "
                                                    name="selectaddress"
                                                    id="flexRadioDefault1"
                                                />

                                                <div className="row">
                                                    <div className="col-md-9">
                                                        <button
                                                            className="w-100 bg-transparent text-start border-0"
                                                            onClick={() => handleAddressSelection(address_id)}
                                                        >
                                                            <div className="d-flex gap-2">
                                                                <div>
                                                                    <span>{name} </span>
                                                                    <span className="badge rounded-0 bg-success">
                                                                        Home
                                                                    </span>
                                                                </div>
                                                                <div className="fw-bold text-dark">
                                                                    {phone_no}
                                                                    {alternate_phone && `, ${alternate_phone}`}
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <ul className="list-unstyled">
                                                                    <li>{`${village}, ${address.address}`}</li>
                                                                    <li>{`Pincode: ${pincode}, Post Office: ${post_office}`}</li>
                                                                    <li>{`${city}, ${district}, ${state}`}</li>
                                                                </ul>
                                                            </div>
                                                        </button>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="text-end">
                                                            <Link
                                                                to={`/editaddress/${address_id}`}
                                                                className="btn btn-danger"
                                                            >
                                                                <i className="ti ti-edit pe-3" />
                                                                Edit
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                        </div>
                                    )
                                );
                            })}
                            <Link
                                to="/addaddress"
                                className="text-white  text-decoration-none mt-3"
                            >
                                <div className="border shadow-sm rounded bg-danger d-flex align-items-center gap-3 justify-content-center">
                                    <p className="h5 color-pink mt-2">Add a New Address</p>
                                    <i className="bi bi-plus-square fs-5"></i>
                                </div>
                            </Link>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    size=""
                    autoFocus={false}
                    centered
                    show={fillBillingAddress}
                    onHide={() => setFillBillingAddress(false)}
                >
                    {/* <Modal.Header className='bg-pink text-white' closeButton>
                        <Modal.Title><h5>Please Complete Your Billing Address Information</h5></Modal.Title>
                    </Modal.Header> */}
                    <Modal.Body>
                        <div className="text-center p-3">

                            {userInformation?.userfrom !== 1 && <h5 className="">
                                Please Complete Your {userInformation?.userfrom === 1 ? "Shipping" : 'Billing'}  Address Information
                            </h5>
                            }
                            {userInformation?.userfrom === 1 && <>
                                <h5 className="mb-2">
                                    For Home Delivery <br />
                                    Please
                                    Complete your shipping details (charges may apply).
                                </h5>
                                <Link
                                    to="/myinformation"
                                    className="text-decoration-none w-50 py-2 btn btn-success mt-3"
                                >
                                    Complete Your Address
                                </Link>
                                <h6 className="fw-semibold">
                                    OR </h6>
                                <h5 className="mb-4">
                                    Quick Pick-Up, Free Delivery – Shopping Made Easy! Pickup  at Your Local Store. Select Store.
                                </h5>
                                <Button
                                    onClick={() => setFillBillingAddress(false)}
                                    className="text-decoration-none w-50 py-2 btn btn-danger mt-3"
                                >
                                    Ok
                                </Button>
                            </>
                            }


                            {/* <button onClick={() => setFillBillingAddress(false)} className='w-50 py-2 btn btn-pink' closeButton>Ok</button> */}

                            {userInformation?.userfrom !== 1 && <Link
                                to="/myinformation"
                                className="text-decoration-none w-50 py-2 btn btn-danger mt-3"
                            >
                                Ok
                            </Link>}

                        </div>
                    </Modal.Body>
                </Modal>
                <Modal
                    size=""
                    autoFocus={false}
                    centered
                    show={showshippingAddress}
                    onHide={() => setShowShippingAddress(false)}
                >
                    <Modal.Header className='bg-danger text-white' closeButton>
                        <Modal.Title><h5 className="fs-5 fw-bold">Your Shipping address</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center py-3 pt-2">
                            <div className="bg-white">
                                {(homeAddressCount === 0 && parseInt(valueFromRadio) === 0) &&
                                    <div id='address' className='px-1'>
                                        <div className="form-check px-0 text-start">

                                            <div className="row">
                                                <div className="col-md-12">

                                                    <div className=" fw-bold fs-6 mb-2 text-end">Delivery Charges : {totalDeliveryCharges}</div>
                                                    <div className="d-flex gap-2">
                                                        <div>
                                                            <span> {billingAddress?.name},</span>

                                                            <span className="badge rounded-0 bg-success">Home</span>


                                                        </div>
                                                        <div className="fw-bold text-dark">   {billingAddress.phone_no && ` ${billingAddress.phone_no}`} {billingAddress.alternate_phone && `, ${billingAddress.alternate_phone}`}</div>
                                                    </div>
                                                    <div>
                                                        <ul className="list-unstyled">

                                                            <li>{billingAddress.village && ` ${billingAddress.village} , `}{billingAddress.address && `${billingAddress.address}`}</li>
                                                            {billingAddress.pincode && (
                                                                <li>
                                                                    <span className=" text-black fw-bold">
                                                                        Pin code:
                                                                    </span>{" "}
                                                                    {billingAddress.pincode &&
                                                                        `${billingAddress.pincode}`}
                                                                </li>
                                                            )}
                                                            {billingAddress.post_office && (
                                                                <li>
                                                                    <span className="text-dark fw-bold">
                                                                        Post Office:
                                                                    </span>{" "}
                                                                    {billingAddress.post_office}
                                                                </li>
                                                            )}
                                                            <li>        {billingAddress.city && `${billingAddress.city}`} {billingAddress.district &&
                                                                `, ${billingAddress.district}`}
                                                                {billingAddress.state &&
                                                                    `, ${billingAddress.state}`}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                }
                                {
                                    allShippingAddress.map((address, i) => {
                                        const { id, user_id, name, default_address, phone_no, school_name, alternate_phone, village, city, address_type, state, district, post_office, pincode } = address;
                                        let address_id = id;
                                        return (
                                            <div key={i} id='address' className='px-1'>
                                                {(valueFromRadio === id || (default_address === 1 && parseInt(valueFromRadio) !== 0)) && <div className="form-check px-0 text-start">

                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            {(userInformation?.userfrom === 1 && address_type !== 2) && <div className=" fw-bold mb-2 text-end fs-6">Delivery Charges : {totalDeliveryCharges}</div>}
                                                            {(userInformation?.userfrom === 1 && address_type !== 1) && <div className=" fw-bold mb-2 text-end fs-6">Free delivery</div>}
                                                            <div className="d-flex gap-2">
                                                                <div>
                                                                    <span>{name} </span>
                                                                    {
                                                                        address_type === 1 ?
                                                                            <span className="badge rounded-0 bg-success">Home</span>
                                                                            :
                                                                            <span className="badge rounded-0 bg-warning">School</span>
                                                                    }

                                                                </div>
                                                                <div className="fw-bold text-dark">{phone_no}{alternate_phone && `, ${alternate_phone}`}</div>
                                                            </div>
                                                            <div>
                                                                <ul className="list-unstyled">
                                                                    {address_type === 2 ? <li className="fw-bold">{school_name && `School Name : ${school_name} , `} </li> : ''}
                                                                    <li>{village && `${village} , `} {address.address && `${address.address} , `}</li>
                                                                    <li>{pincode && `Pincode:  ${pincode}`}{post_office && ` Post Office:  ${post_office}`}</li>
                                                                    {/* <li>{`Pincode: ${pincode}, Post Office: ${post_office!==null && post_office}`}</li> */}
                                                                    <li>{city && `${city} , `}{` ${district}, ${state}`}</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}

                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {/* <button onClick={() => setFillBillingAddress(false)} className='w-50 py-2 btn btn-pink' closeButton>Ok</button> */}
                            <div className="text-md-end mb-1">
                                <button onClick={proceedToCheckout} className="btn btn-success border-dark w-100">Proceed To Buy</button>
                            </div>
                            <div className="text-md-end mb-1">
                                <Link to={'/addaddress'} className="btn btn-danger border-dark w-100"><i className="ti ti-plus me-3" />Add New Address </Link>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* <Modal
                    size=""
                    autoFocus={false}
                    centered
                    show={showshippingAddress}
                    onHide={() => setShowShippingAddress(false)}
                >
               
                    <Modal.Body>
                        <div className="text-center py-3 pt-2">
                            <div className="bg-white">
                                <h5 className="fw-bold fs-3 mb-2">Your Shipping address</h5>
                                {
                                    allShippingAddress.map((address, i) => {
                                        const { id, user_id, name, default_address, phone_no, school_name, alternate_phone, village, city, address_type, state, district, post_office, pincode } = address;
                                        let address_id = id;
                                        return (
                                            <div key={i} id='address' className='px-1'>
                                                {default_address === 1 && <div className="form-check px-0">
                                                     <button onClick={() => handleAddressSelection(address_id)} className='bg-transparent w-100 border-0 text-start'>
                                                        <div className="row">
                                                            <div className="col-md-9">
                                                                <div className="d-flex gap-2">
                                                                    <div>
                                                                        <span>{name} </span>
                                                                        {
                                                                            address_type === 1 ?
                                                                                <span className="badge rounded-0 bg-success">Home</span>
                                                                                :
                                                                                <span className="badge rounded-0 bg-warning">School</span>
                                                                        }

                                                                    </div>
                                                                    <div className="fw-bold text-dark">{phone_no}{alternate_phone && `, ${alternate_phone}`}</div>
                                                                </div>
                                                                <div>
                                                                    <ul className="list-unstyled">
                                                                        <li>{`${village}, ${address.address}`}</li>
                                                                        <li>{`Pincode: ${pincode}, Post Office: ${address.post_office && post_office}`}</li>
                                                                        <li>{`${city}, ${district}, ${state}`}</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </button>

                                                </div>}

                                            </div>
                                        )
                                    })
                                }
                            </div>
                           <div className="text-md-end mb-1">
                                <Link to={'/addaddress'} className="btn btn-danger border-dark w-100"><i className="ti ti-plus me-3" />Add New Address </Link>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal> */}

                <Modal size="" autoFocus={false} centered show={chooseAddressModal}>
                    {/* <Modal.Header className='bg-pink text-white' closeButton>
                        <Modal.Title><h5>Please Complete Your Billing Address Information</h5></Modal.Title>
                    </Modal.Header> */}
                    <Modal.Body>
                        <div className="text-center p-3">
                            <h5 className="mb-4">Please Choose Your Shipping Address</h5>
                            <button
                                onClick={() => setChooseAddressModal(false)}
                                className="w-50 py-2 btn btn-danger"
                                closeButton
                            >
                                Ok
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
            </main >
        </>
    );
};

export default Cart;
